import {graphql, useStaticQuery} from 'gatsby';
import React, {Fragment} from 'react';
import Typography from '@material-ui/core/Typography';
import styles from './CloudDevelopmentMigration.module.scss';
import Advantages from '../Advantages';
import ExpandingCard from '../ExpandingCard';
import Feed from '../Feed';
import InnerPagesUpperBanner from '../InnerPagesUpperBanner';
import LeaveMessageBanner from '../LeaveMessageBanner';
import SuccessStoriesBanner from '../SuccessStoriesBanner';
import UseCases from '../UseCases';
import {
  CDM_ADVANTAGES_CONTENT, CDM_CONSULTING_CARDS, CDM_CONSULTING_HEADING, CDM_CONSULTING_TEXT, CDM_DEVELOPMENT_CARDS,
  CDM_DEVELOPMENT_HEADING, CDM_DEVELOPMENT_TEXT, CDM_INSIGHTS_HEADING, CDM_MANAGEMENT_CARDS, CDM_MANAGEMENT_HEADING,
  CDM_MANAGEMENT_TEXT, CDM_SUBHEADING, CDM_SUCCESS_STORIES_TEXT, CDM_TITLE, CDM_USE_CASES_CONTENT, CDM_USE_CASES_HEADING
} from '../../constants/cloud-development-and-migration';

const CloudDevelopmentMigration = () => {
  const {
    background, icons, insightsPosts, minus, plus, successStoriesBackground, useCasesImages
  } = useStaticQuery(graphql`
    query {
      background: allFile(
        filter: {
          sourceInstanceName: { eq: "CDM" }
          name: { eq: "bg" }
        }
      ) {
        nodes {
          name
          publicURL
        }
      }
      icons: allFile(
        filter: {
          sourceInstanceName: { eq: "CDM" }
          extension: { eq: "svg" }
        }
      ) {
        nodes {
          name
          publicURL
        }
      }
      insightsPosts: allMarkdownRemark(
        filter: {
          fields: {
            slug: {
              in: ["/posts/building-a-modern-digital-enterprise-with-google-cloud-data-fusion", "/posts/google-cloud-migration-strategies-and-opportunities-beyond-usual-cloud-efficiencies", "/posts/10-reasons-why-it-outsourcing-in-ukraine-is-a-perfect-choice-for-global-businesses"]
            }
          }
          frontmatter: {
            template: {
              eq: "post"
            }
            draft: {
              ne: true
            }
          }
        }
        ) {
          nodes {
            fields {
              slug
              categorySlug
              tagSlugs
            }
            frontmatter {
              title
              date
              category
              description
              socialImage
              tags
            }
          }
        }
        plus: allFile(
          filter: {
            sourceInstanceName: { eq: "otherImages" }
            name: { eq: "plus" }
          }
          ) {
              nodes {
                name
                publicURL
              }
          }
        minus: allFile(
          filter: {
            sourceInstanceName: { eq: "otherImages" }
            name: { eq: "minus" }
            }
        ) {
          nodes {
            name
            publicURL
          }
        }
        successStoriesBackground: allFile(
          filter: {
            sourceInstanceName: { eq: "otherImages" }
            name: { eq: "success-stories-bg" }
          }
        ) {
          nodes {
            name
            publicURL
          }
        }
        useCasesImages: allFile(
          filter: {
            sourceInstanceName: { eq: "CDM" }
            relativeDirectory: { eq: "use-cases" }
          }
        ) {
          nodes {
            name
            publicURL
          }
        }
    }
  `);

  const postsEdges = insightsPosts.nodes.map(node => ({node}));
  const useCasesImagesMap = useCasesImages.nodes.reduce((acc, img) => ({...acc, [img.name]: img.publicURL}), {});

  const renderCards = (subHeading, cards) => <Fragment>
    <div className={styles['section-text']}>{subHeading}</div>
    <div className={styles['section-cards']}>
      {cards.map(({icon, text, title}) => {
        return <div className={styles['section-card']} key={icon}>
          <ExpandingCard
            collapser={minus.nodes[0].publicURL}
            expander={plus.nodes[0].publicURL}
            img={icons.nodes.find(({name}) => name === icon).publicURL}
            smallCard
          >
            {{
              collapsedView: <Typography
                className={styles['section-card-heading']}
                color="secondary"
                variant="h4"
              >
                {title}
              </Typography>,
              expandedView: <div className={`expanding-card-container ${styles['section-card-container']}`}>
                <p className={styles['section-card-paragraph']}>{text}</p>
              </div>
            }}
          </ExpandingCard>
        </div>;
      })}
    </div>
  </Fragment>;

  return <div className={styles['cdm-page']}>
    <InnerPagesUpperBanner
      bg={background.nodes[0].publicURL}
      className={styles['cdm-banner']}
      description={[CDM_SUBHEADING]}
      descriptionClassName={styles['cdm-banner-description']}
      text={[CDM_TITLE]}
      textClassName={styles['cdm-banner-text']}
    />
    <div className={styles['section-container']}>
      <div className={styles.container}>
        <Typography align="center" className={styles.heading} color="secondary" variant="h2">
          {CDM_CONSULTING_HEADING}
        </Typography>
        {renderCards(CDM_CONSULTING_TEXT, CDM_CONSULTING_CARDS)}
      </div>
    </div>
    <div className={`${styles['section-container']} ${styles.management}`}>
      <div className={styles.container}>
        <Typography align="center" className={styles.heading} color="secondary" variant="h2">
          {CDM_MANAGEMENT_HEADING}
        </Typography>
        {renderCards(CDM_MANAGEMENT_TEXT, CDM_MANAGEMENT_CARDS)}
      </div>
    </div>
    <div className={styles['section-container']}>
      <div className={styles.container}>
        <Typography align="center" className={styles.heading} color="secondary" variant="h2">
          {CDM_DEVELOPMENT_HEADING}
        </Typography>
        {renderCards(CDM_DEVELOPMENT_TEXT, CDM_DEVELOPMENT_CARDS)}
      </div>
    </div>
    <SuccessStoriesBanner
      backgroundImage={successStoriesBackground.nodes[0].publicURL}
      className={styles['success-stories']}
      descriptionClassName={styles['success-stories-description']}
      subHeading={[CDM_SUCCESS_STORIES_TEXT]}
      textClassName={styles['success-stories-text']}
    />
    <div className={`${styles['section-container']} ${styles['use-cases']}`}>
      <div className={styles.container}>
        <Typography align="center" className={styles.heading} color="secondary" variant="h2">
          {CDM_USE_CASES_HEADING}
        </Typography>
        <UseCases content={CDM_USE_CASES_CONTENT} images={useCasesImagesMap} />
      </div>
    </div>
    <div className={`${styles['section-container']} ${styles['advantages']}`}>
      <div className={styles.container}>
        <Advantages content={CDM_ADVANTAGES_CONTENT} />
      </div>
    </div>
    <div className={`${styles['section-container']} ${styles['insights']}`}>
      <div className={styles.container}>
        <Typography align="center" className={styles.heading} color="secondary" variant="h2">
          {CDM_INSIGHTS_HEADING}
        </Typography>
        <Feed edges={postsEdges}/>
      </div>
    </div>
    <LeaveMessageBanner />
  </div>;
};

export default CloudDevelopmentMigration;
