import {graphql, useStaticQuery} from 'gatsby';
import React from 'react';
import CloudDevelopmentMigration from '../components/CloudDevelopmentMigration';
import Layout from '../components/Layout';
import {useSiteMetadata} from '../hooks';

const CloudDevelopmentMigrationTemplate = ({location}) => {
  const {bg} = useStaticQuery(graphql`
    query {
      bg: allFile(
        filter: {
          sourceInstanceName: { eq: "preview" }
          name: { eq: "CDM" }
        }
      ) {
        nodes {
          name
          publicURL
        }
      }
    }
  `);
  const {subtitle} = useSiteMetadata();

  return <Layout
    description={subtitle}
    location={location}
    socialImage={bg.nodes[0].publicURL}
    title="Cloud Development & Migration"
  >
    <CloudDevelopmentMigration />
  </Layout>;
};

export default CloudDevelopmentMigrationTemplate;
